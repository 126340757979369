import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import UAParser from 'ua-parser-js';


function Stats({ shortcode_id }) {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [geoStats, setGeoStats] = useState([]);
  const [redirects, setRedirects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [dailyRedirects, setDailyRedirects] = useState([]);

  useEffect(() => {
    fetchData();
  }, [date, currentPage, selectedCountries]);

  useEffect(() => {
    fetchDailyRedirects();
  }, [date]);

  const fetchData = () => {
    setLoading(true);
    setError(null);
    fetchStats();
    fetchRedirects();
  };

  const fetchStats = () => {
    fetch(`/shortcodes/${shortcode_id}/api/stats?date=${date}`)
      .then((response) => response.json())
      .then((data) => {
        setGeoStats(data);
      })
      .catch((error) => {
        setError('Failed to fetch stats');
        console.error('Failed to fetch stats:', error);
      })
      .finally(() => setLoading(false));
  };

  const fetchRedirects = () => {
    const countries = selectedCountries.map(c => c.value).join(',');
    fetch(`/shortcodes/${shortcode_id}/api/redirects?date=${date}&page=${currentPage}&countries=${countries}`)
      .then((response) => response.json())
      .then((data) => {
        setRedirects(data.redirects);
        setTotalPages(data.total_pages);
        updateCountryOptions(data.all_countries);
      })
      .catch((error) => {
        setError('Failed to fetch redirects');
        console.error('Failed to fetch redirects:', error);
      })
      .finally(() => setLoading(false));
  };

  const fetchDailyRedirects = () => {
    fetch(`/shortcodes/${shortcode_id}/daily_redirects?date=${date}`)
      .then((response) => response.json())
      .then((data) => {
        const labels = Object.keys(data).map(hour => `${hour}:00`);
        const counts = Object.values(data);
        setDailyRedirects({ labels, counts });
      })
      .catch((error) => {
        setError('Failed to fetch daily redirects');
        console.error('Failed to fetch daily redirects:', error);
      });
  };

  const updateCountryOptions = (countries) => {
    const options = countries.map(country => ({ value: country, label: country }));
    setCountryOptions(options);
  };

  const handleDateChange = (selectedOption) => {
    setDate(selectedOption.value);
    setCurrentPage(1);
    setSelectedCountries([]);
  };

  const handleCountryChange = (selectedOptions) => {
    setSelectedCountries(selectedOptions || []);
    setCurrentPage(1);
  };

  const dateOptions = Array.from({ length: 10 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - i);
    return { value: date.toISOString().split('T')[0], label: date.toISOString().split('T')[0] };
  });

  const handlePageChange = (page) => {
    if (page !== '...') {
      setCurrentPage(page);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const CustomOption = ({ children, isSelected, innerProps }) => (
    <div {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
      <input type="checkbox" checked={isSelected} readOnly style={{ marginRight: '8px' }} />
      {children}
    </div>
  );

  const getBrowserIcon = (browserName) => {
    switch (browserName.toLowerCase()) {
      case 'chrome':
        return '🌐';
      case 'firefox':
        return '🦊';
      case 'safari':
        return '🧭';
      case 'edge':
        return '🔵';
      case 'opera':
        return '🅾️';
      default:
        return '❓';
    }
  };

  const getOSIcon = (osName) => {
    switch (osName.toLowerCase()) {
      case 'windows':
        return '🪟';
      case 'mac os':
        return '🍏';
      case 'linux':
        return '🐧';
      case 'android':
        return '🤖';
      case 'ios':
        return '📱';
      default:
        return '❓';
    }
  };
  
  const generatePageNumbers = (currentPage, totalPages, maxButtons = 10) => {
    let pages = [];
    const half = Math.floor(maxButtons / 2);
  
    if (totalPages <= maxButtons) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= half) {
      pages = [...Array.from({ length: half * 2 }, (_, i) => i + 1), '...', totalPages];
    } else if (currentPage >= totalPages - half) {
      pages = [1, '...', ...Array.from({ length: half * 2 }, (_, i) => totalPages - half * 2 + i + 1)];
    } else {
      pages = [1, '...', ...Array.from({ length: half }, (_, i) => currentPage - half + i + 1), currentPage, ...Array.from({ length: half }, (_, i) => currentPage + i + 1), '...', totalPages];
    }
  
    return pages;
  };

  return (
    <div className="pt-4">
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
              {/* Header */}
              <div className="px-6 py-4 border-b border-gray-200">
                <div className="mt-2 flex flex-wrap gap-2">
                  <Select
                    options={dateOptions}
                    onChange={handleDateChange}
                    defaultValue={dateOptions[0]}
                    className="w-48"
                  />
                  <Select
                    options={countryOptions}
                    onChange={handleCountryChange}
                    value={selectedCountries}
                    isMulti
                    placeholder="Filter by country"
                    className="w-64"
                    styles={customStyles}
                    components={{ Option: CustomOption }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                  <button
                    onClick={fetchData}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
                  >
                    Refresh
                  </button>
                </div>
              </div>
              {/* End Header */}

              {loading && <p className="p-4">Loading...</p>}
              {error && <p className="p-4 text-red-500">{error}</p>}

              {/* Today's Geo Stats Table */}
              <div className="overflow-hidden">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Geo</th>
                      <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase max-w-xs truncate wrap">Link</th>
                      <th scope="col" className="px-6 py-2 text-right text-xs font-medium text-gray-500 uppercase">Redirects</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {geoStats.map((geo) => (
                      geo.links.map((linkStat, index) => (
                        <tr key={`${geo.id}-${index}`} className="">
                          {index === 0 && (
                            <td rowSpan={geo.links.length} className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                              {geo.geo}
                            </td>
                          )}
                          <td className="px-6 py-2 text-sm text-gray-800 max-w-xs truncate wrap">
                            <a href={linkStat.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 truncate wrap">
                              {linkStat.url}
                            </a>
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-800 text-right">
                            {linkStat.count}
                          </td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Redirects Table */}
              <div className="overflow-hidden mt-6">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">Time</th>
                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">IP</th>
                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">Country</th>
                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">Link</th>
                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">System</th>
                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">Browser</th>
                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 max-w-sm uppercase">Referer</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {redirects.map((redirect) => {
                      const parser = new UAParser();
                      const uaResult = parser.setUA(redirect.user_agent).getResult();
                      const osName = uaResult.os.name || 'Unknown';
                      const browserName = uaResult.browser.name || 'Unknown';

                      return (
                        <tr key={redirect.id} className="">
                          <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm">
                            {new Date(redirect.timestamp).toLocaleString()}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm">{redirect.ip}</td>
                          <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm">{redirect.country}</td>
                          <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm truncate">
                            <a href={redirect.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                              {redirect.link}
                            </a>
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm">
                            {getOSIcon(osName)} {osName}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm">
                            {getBrowserIcon(browserName)} {browserName}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800 max-w-sm">
                            {redirect.referer ? redirect.referer.split('?')[0] : ''}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="px-6 py-4 border-t border-gray-200">
                <div className="flex justify-center">
                  {generatePageNumbers(currentPage, totalPages).map((page, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(page)}
                      disabled={page === '...'}
                      className={`px-3 py-1 mx-1 text-sm rounded-md ${
                        page === currentPage
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                      } ${page === '...' ? 'cursor-default' : ''}`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
