import React, { useState, useEffect } from 'react';

function LiveLogTable({ }) {
  const [visitors, setVisitors] = useState([]); 

  useEffect(() => {
    // Call the fetchUpdates function here to fetch updates when the component mounts
    fetchUpdates();
    // Set up a timer to periodically fetch updates every 2 seconds
    const updateInterval = setInterval(fetchUpdates, 2000);
  
    // Clear the timer when the component unmounts
    return () => {
      clearInterval(updateInterval);
    };
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts


  const fetchUpdates = () => {
    // Fetch updates from your server
    fetch(`/live/get_updates`)
      .then((response) => response.json())
      .then((data) => {
        if (data.visitors.length > 0) {
          setVisitors(data.visitors);
        }
      })
      .catch((error) => {
        console.error('Error fetching updates:', error);
      });
  }; 

  // take first 50 visitors
  let firstVisitors = visitors.slice(0, 50);

  return (
    <div className="box-item" style={{ marginTop: '15px' }}>
      <table className="table banners-table">
        <thead>
          <tr>
            <th></th>
            <th>Time</th>
            <th>IP</th>
            <th>User Agent</th>
            <th>Route</th>
          </tr>
        </thead>
        <tbody>
          {firstVisitors.map((visitor, index) => (
            <tr key={index}>
              <td>{index}</td>
              <td>{visitor.visit_time}</td>
              <td>{visitor.ip_address}</td>
              <td>{visitor.user_agent}</td>
              <td>{visitor.url}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LiveLogTable;