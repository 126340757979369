import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Search, Plus, ExternalLink, Pencil, BarChart2, Trash2 } from 'lucide-react';

function ShortcodesTable({ shortcodes, affiliateTags = [] }) {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredShortcodes, setFilteredShortcodes] = useState(shortcodes);
  const [selectedTag, setSelectedTag] = useState(null);
  const itemsPerPage = 10;

  const pageCount = Math.ceil(filteredShortcodes.length / itemsPerPage);

  useEffect(() => {
    let filteredData = shortcodes;

    if (selectedTag) {
      filteredData = filteredData.filter(shortcode => shortcode.affiliate_tag === selectedTag);
    }

    if (search.length >= 2) {
      filteredData = filteredData.filter(shortcode => {
        const nameMatch = shortcode.name.toLowerCase().includes(search.toLowerCase());
        const urlNameMatch = shortcode.url_name.toLowerCase().includes(search.toLowerCase());
        return nameMatch || urlNameMatch;
      });
    }

    setFilteredShortcodes(filteredData);
  }, [search, shortcodes, selectedTag]);

  const offset = currentPage * itemsPerPage;
  const shortcodesToDisplay = filteredShortcodes.slice(offset, offset + itemsPerPage);

  const highlightMatch = (text) => {
    if (search && text) {
      const regex = new RegExp(`(${search})`, 'gi');
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span key={index} className="bg-blue-100 text-blue-900">
            {part}
          </span>
        ) : (
          part
        )
      );
    }
    return text;
  };

  const copyUrl = (url, div) => {
    navigator.clipboard.writeText(url);
    div.classList.add('copy-animation');
    toastr.info("Shortcode copied to clipboard", "Success", {"timeOut": "1500"});
    setTimeout(() => {
      div.classList.remove('copy-animation');
    }, 1000);
  };

  const handleTagClick = (tag) => {
    setSelectedTag(tag === selectedTag ? null : tag);
    setCurrentPage(0);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this shortcode?')) {
      fetch(`/shortcodes/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            window.location.replace(window.location.href);
          }
        })
        .catch((error) => {
          console.error('Error deleting shortcode:', error);
        });
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: false,
    };
    return date.toLocaleString('en', options);
  }

  return (
    <div className="w-full p-6 bg-white rounded-xl shadow-sm">
      <div className="relative mb-6">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Search shortcodes..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
    
      <div className="flex items-center gap-2 mb-6 overflow-x-auto py-1">
        <button
          onClick={() => handleTagClick(null)}
          className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors duration-150 border 
            ${!selectedTag ? 'bg-blue-50 text-blue-600 border-blue-200' : 'bg-gray-50 text-gray-600 border-gray-200 hover:bg-gray-100'}`}
        >
          All
        </button>
        {affiliateTags.map(tag => (
          <button
            key={tag}
            onClick={() => handleTagClick(tag)}
            className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors duration-150 border
              ${selectedTag === tag ? 'bg-blue-50 text-blue-600 border-blue-200' : 'bg-gray-50 text-gray-600 border-gray-200 hover:bg-gray-100'}`}
          >
            {tag}
          </button>
        ))}
        <a
          href="/affiliate_tags"
          className="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-green-50 text-green-600 border border-green-200 hover:bg-green-100 transition-colors duration-150"
        >
          <Plus className="w-4 h-4 mr-1" />
          Add Tag
        </a>
      </div>

      <div className="overflow-hidden rounded-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Affiliate Tag</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shortcode</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {shortcodesToDisplay.map((shortcode) => (
              <tr key={shortcode.id} className="hover:bg-gray-50 transition-colors duration-150">
                <td className="px-6 py-2">
                  <div className="text-sm text-gray-900 font-medium">{highlightMatch(shortcode.name)}</div>
                  <div className="text-xs text-gray-500 mt-1">Created at: {formatTimestamp(shortcode.created_at)}</div>
                  <div className="text-xs text-gray-500">Created by: {shortcode.created_by_role} ({shortcode.created_by_email})</div>
                </td>
                <td className="px-6 py-2">
                  {shortcode.affiliate_tag ? (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-600">
                      {highlightMatch(shortcode.affiliate_tag)}
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                      N/A
                    </span>
                  )}
                </td>
                <td className="px-6 py-2">
                  <div
                    className="text-sm text-gray-600 px-3 py-2 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors duration-150 flex items-center gap-2"
                    onClick={(e) => copyUrl(`https://paripesa.bet/${shortcode.url_name}`, e.currentTarget)}
                  >
                    {highlightMatch(`https://paripesa.bet/${shortcode.url_name}`)}
                    <ExternalLink className="w-4 h-4 text-gray-400" />
                  </div>
                </td>
                <td className="px-6 py-2 text-right text-sm font-medium space-x-3">
                  <a href={`/shortcodes/${shortcode.id}/edit`} className="text-blue-600 hover:text-blue-800">
                    <Pencil className="w-4 h-4 inline" />
                  </a>
                  <a href={`/shortcodes/${shortcode.id}/stats`} className="text-blue-600 hover:text-blue-800">
                    <BarChart2 className="w-4 h-4 inline" />
                  </a>
                  <button
                    onClick={() => handleDelete(shortcode.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <Trash2 className="w-4 h-4 inline" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="flex items-center justify-center gap-2"
          pageClassName="inline-flex items-center justify-center w-8 h-8 text-sm rounded-lg text-gray-600 hover:bg-gray-100"
          pageLinkClassName="flex items-center justify-center w-full h-full"
          previousClassName="inline-flex items-center justify-center px-3 h-8 text-sm font-medium rounded-lg text-gray-600 hover:bg-gray-100"
          nextClassName="inline-flex items-center justify-center px-3 h-8 text-sm font-medium rounded-lg text-gray-600 hover:bg-gray-100"
          breakClassName="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-600"
          activeClassName="!bg-blue-50 !text-blue-600 font-medium"
          disabledClassName="opacity-50 cursor-not-allowed"
        />
      </div>
    </div>
  );
}

export default ShortcodesTable;