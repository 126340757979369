// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'font-awesome/css/font-awesome.min.css';

import $ from 'jquery';
import 'toastr/toastr.scss';
import toastr from 'toastr';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

window.toastr = toastr; // Make Toastr available globally

// make jquery available to webpack
global.$ = jQuery;
global.jQuery = jQuery;

Rails.start()
Turbolinks.start()
ActiveStorage.start()


console.log('Hello World from Webpacker from app/javascript/packs/application.js');

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)


// turbolinks ready function
document.addEventListener('turbolinks:load', () => {
    $('#new-folder').click(function () {
        $('#folder-wrapper').show();
    });


});